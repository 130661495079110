<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">账号列表</div>
        <el-button type="primary" @click="createUser" class="cardTitleBtn">新建账号</el-button>
      </el-col>
      <el-col :span="24">
         <el-table :data="userList" style="width: 100%">
            <el-table-column prop="email" label="邮箱" sortable></el-table-column>
            <el-table-column prop="account" label="账号" sortable></el-table-column>
            <el-table-column prop="updateTime" label="最近修改时间" sortable></el-table-column>
            <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
            <el-table-column fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button @click="getDetail(scope.row)" type="primary" size="mini">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      userList:[],
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getUserList()
  },  
  methods: {
    // 获取管理员列表
    getUserList() {
      let self = this;
      self.$axios
        .get("/api/admin/list", {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.userList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message:error.response.data.message,
            type: "warning",
          });
        });
    },
    // 编辑
    getDetail(row) {
      let self = this;
      self.$router.push({ name: "admin_detail", query: { guid: row.guid } });
    },
    // 新建
    createUser() {
      let self = this;
      self.$router.push({ name: "admin_detail", query: { guid: null } });
    }
  },
};
</script>

<style scoped>
</style>